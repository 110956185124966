.input-number-a {
  width: 100%;
  margin-bottom: 2rem;
}
.paragraph {
  font-weight: 500;
  font-size: 16px;
}
.error-text{
  color: #da4242;
}
.underlined-link {
  color: #2c56b6;
  font-weight: 500;
  text-decoration: underline;
  display: inline-block;
}

.driver-chosen-car-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 24px;
  font-size: 16px;
  font-weight: 500;
  color: #1a3d56;
  border: 2px solid #91a2ab;
  border-radius: 8px;
  margin-bottom: 56px;
  .car-image {
    width: 80px;
    height: 40px;
    margin-right: 16px;
    img {
      object-fit: cover;
    }
  }
}

.with-y-divider {
  &:not(:last-child) {
    border-bottom: 1px solid #e5e5e5;
  }
}
.only-feedback-form-item {
  .ant-form-item-control-input {
    display: none;
  }
  .ant-form-item-explain {
    margin: 8px 0;
  }
}

.checkbox-group {
  margin-top: 12px;
  .checkbox-option-row {
    padding: 12px 0;
  }
}
.checkbox-option {
  font-size: 16px;
  font-weight: 500;
  color: #1a3d56;
}

.driver-contact-info {
  &-btn {
    padding: 0;
    height: unset;
    span {
      text-decoration: underline;
      font-size: 16px;
      font-weight: 500;
    }
  }

  &-modal {
    p {
      font-size: 16px;
      span {
        font-weight: 500;
      }
    }
  }
}
.successful-payment-btn {
  color: #46bc14 !important;
  background-color: transparent !important;
  border: 1.5px solid currentColor !important;
  cursor: auto !important;
}
