.notifications-tab {
  .ant-badge-count {
    background: #91a2ab;
    margin-left: 7px;
    color: #fff;
    transition: 0.4s;
  }
}
.tables-tab-bar {
  .ant-tabs-tab-active {
    .notifications-tab {
      .ant-badge-count {
        background: #2c56b6;
        color: #fff;
      }
    }
  }
}

.seen {
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 7px;
  height: 32px;
  border-radius: 4px;
  border: none;
  background: none;
  color: #91a2ab;
  box-shadow: none;
  svg {
    transition: 0.2s;
    fill: #91a2ab;
  }
  &:hover {
    background-color: #2c55b642;
    color: #2c56b6;
    svg {
      transition: 0.2s;
      fill: #2c56b6;
    }
  }
  .text-seen {
    color: #91a2ab;
  }
  &:active,
  &:focus {
    color: #91a2ab;
    background-color: initial;
    &:hover {
      background-color: #2c55b642;
      color: #2c56b6;
    }
  }
}
.border-cell:not(th) {
  border-left: 1px solid rgb(229, 229, 229);
}
