.dot {
  // states: 'error' | 'process' | 'finish' | 'wait'

  width: 8px;
  height: 8px;
  background-color: #d0d8dd;
  border: 1px solid transparent;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.finish,
  &.process,
  &.error {
    width: 18px;
    height: 18px;
    background: #2c56b6;
  }
  &.finish,
  &.process {
    background: #2c56b6;
  }
  &.error {
    background: #da4242;
  }
  &.solid {
    background: transparent;
    color: #91a2ab;
    border-color: #91a2ab;
  }
  &.solid {
    border-color: currentColor;
  }
  .dot-inner-mark {
    color: #fff;
  }
  &.solid .dot-inner-mark {
    color: inherit;
  }

  &.finish {
    .dot-inner-mark {
      display: inline-block;
      margin-left: 1px;
      margin-bottom: 1px;
      &:after {
        color: inherit;
        /*Add another block-level blank space*/
        content: '';
        display: block;
        /*Make it a small rectangle so the border will create an L-shape*/
        width: 4.5px;
        height: 9px;
        /*Add a border on the bottom and left, creating that 'L' */
        border: solid currentColor;
        border-width: 0 2px 2.5px 0;
        // border-radius: 2px;
        /*Rotate the L 45 degrees to turn it into a checkmark*/
        transform: rotate(45deg);
      }
    }
  }

  &.process {
    .dot-inner-mark {
      width: 6px;
      height: 6px;
      background-color: #fff;
      border-radius: 50%;
    }
  }
  &.error {
    .dot-inner-mark {
      width: 100%;
      height: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: relative;
      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 2px;
        height: 9px;
        left: 50%;
        background-color: currentColor;
      }
      &:before {
        transform: translate(-50%) rotate(45deg);
      }
      &:after {
        transform: translate(-50%) rotate(-45deg);
      }
    }
  }
}
