.with-custom-scrollbar {
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(168, 168, 168, 0.7);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(168, 168, 168, 0.4);
  }
}
.sidebar-root {
  overflow-x: visible !important;
  width: 288px;
  max-width: 288px;
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 50;

  & + .main-container {
    padding-left: 288px;
  }
  &.is-collapsed + .main-container {
    padding-left: 80px;
  }
}
@media screen and (max-width: 767px) {
  .sidebar-root + .main-container {
    padding-left: 80px !important;
  }
}
.sidebar-inner {
  overflow-y: auto;
  overflow-x: hidden;
}
.collapse-btn {
  position: absolute;
  right: -14px;
  top: 48px;

  width: 32px;
  height: 32px;
  padding: 2px;
  border-radius: 9999px;

  background-color: white;
  border: 2px solid rgba(145, 162, 171, 0.3);
  transition: all 0.2s;

  .collapse-icon {
    fill: #91a2ab;
    transition: all 0.2s;

    &.rotate {
      transform: rotate(180deg);
    }
  }
  &:hover,
  &:focus {
    border-color: #2c56b6;
    .collapse-icon {
      fill: #2c56b6;
    }
  }
}
.sidebar-menu {
  padding: 0 24px !important;
  padding-top: 12px !important;
}
.user-profile-container {
  // box-shadow: 0px -20px 40px rgba(208, 216, 221, 0.7);
  // border-top: 2px solid #d0d8dd;
  // z-index: 100;
  .divider {
    margin: 12px auto;
  }
}
.sidebar-root.is-collapsed {
  .sidebar-menu {
    padding: 0 12px !important;
  }
  .user-avatar {
    margin: 0 !important;
  }
  .user-profile-btn {
    padding: 0 12px;
    margin-left: auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .user-info-container,
  .sidebar-header,
  .ant-menu-title-content {
    display: none !important;
  }

  .ant-menu-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.notification-count-badge.relative .ant-badge-count {
  position: relative;
}

.sidebar-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px 24px 0;
  overflow: hidden;
  position: relative;
  .sidebar-logo {
    width: 100%;
  }
  .sidebar-heading {
    display: inline-block;
    color: #2c56b6;
    font-size: 16px;
    font-weight: bold;
    line-height: inherit;
    display: flex;
    flex-direction: column;
    margin: 0 24px;

    & > *:nth-child(1) {
      margin-right: 24px;
    }
    & > *:nth-child(2) {
      margin-left: 24px;
    }
  }
}
.ant-layout-sider {
  background-color: #fff;

  .divider {
    height: 2px;
    width: 90%;
    &.full-width {
      width: 100%;
    }
    background-color: #e5e5e5;
    margin: 24px auto 12px;
    &.without-margin {
      margin: 0;
    }
  }
}
.ant-menu {
  color: #91a2ab;
  font-weight: 500;
  padding: 0 24px;

  .ant-menu-item-group:not(:last-child) {
    margin-bottom: 44px;
  }
  .ant-menu-item-group-title {
    text-transform: uppercase;
    color: rgba(26, 61, 86, 0.3);
    padding: 0;
    padding-bottom: 4px;
    line-height: 1.3;
  }
  .ant-menu-item {
    padding: 8px !important;
    margin: 8px 0;
    .ant-menu-title-content {
      margin-left: 8px;
    }
    a {
      color: inherit;
    }
    &.ant-menu-item-selected {
      background-color: rgba(10, 126, 234, 0.2);
      color: #2c56b6;
      border-radius: 8px;
      font-weight: bold;
    }
  }
}
.ant-badge.notification-count-badge {
  width: 100%;
  color: inherit !important;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border: none;
}
.ant-layout-sider-children {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.avatar-sidebar {
  padding: 20px 16px;
}
.avatar-sidebar {
  display: flex;

  &__info {
    margin: auto 8px;
  }
  .name {
    font-weight: bold;
  }
  .email {
    color: rgba(0, 0, 0, 0.3);
  }
}

.sidebar-user-profile-btn {
  // padding-bottom: 32px;
}
