.float-label-wrapper {
  position: relative;

  textarea,
  input:not([type='hidden']),
  .ant-select-selector {
    padding: 6px 10px;
    padding-top: 18px;
    min-height: 56px;
  }
  .ant-select-selection-search-input {
    padding-top: 12px !important;
  }
  .ant-select-selector {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }
  .ant-picker {
    padding: 0;
  }
}

.label {
  font-size: 16px;
  color: #91a2ab;
  font-weight: 500;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 18px;
  transition: 0.2s ease all;

  &.float {
    top: 6px;
    font-size: 12px;
  }
}
