.login-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
.forgot-pass-link {
  margin-bottom: 36px;
  display: inline-block;
  align-self: flex-end;
  font-size: 12px;
  color: #244658;
  &:hover,
  &:focus {
    color: #2c56b6;
  }
  &:active {
    color: lighten(#2c56b6, 15%);
  }
}
