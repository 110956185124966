.user-profile-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  height: 56px;
  width: 100%;
  margin: 16px 0 24px;
  padding: 0 24px;

  .user-info-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    overflow-x: hidden;
  }

  .user-avatar {
    background-color: #cee5fb;
    margin-right: 12px;
  }

  .chevron-icon {
    fill: #91a2ab;
    margin-left: auto;
    width: 24px;
    height: 24px;
    transition: all 0.2s;
  }
  &:hover,
  &:focus {
    .chevron-icon {
      fill: #000000;
    }
  }

  .user-name,
  .user-email {
    line-height: 1.5;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .user-name {
    color: #000000;
    font-size: 16px;
  }
  .user-email {
    color: #b2b2b2;
    font-size: 14px;
  }

  position: relative;
  .user-options-menu-container {
    .ant-popover-inner-content {
      padding: 0;
    }
    // position: absolute;
    // right: 0;
    // bottom: 0;
    .profile-menu {
      padding: 0;
      border: none;
      border-radius: 8px;
      overflow: hidden;
    }
    .profile-menu-item {
      margin: 0;
      &:not(:last-child) {
        margin-bottom: 4px;
      }
      padding: 12px 24px !important;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &.readonly {
        cursor: auto;
        color: inherit;
      }
    }
    .profile-menu-item.danger {
      color: #ff4d4f;
      &:hover,
      &:focus {
        background-color: #ff4d4f;
        color: white;
      }
    }
  }
}
