.register {
  min-height: 100vh;
  padding: 40px 0;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &__wrapper {
    margin-top: 40px;
    width: 416px;
    max-width: 90%;
    background-color: #fff;
    padding: 40px;
    border-radius: 8px;
  }
}
.note {
  padding: 16px 14px;
  background-color: #f5f7f8;
  border-radius: 8px;
  &__title {
    color: #244658;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
  }
  &__text {
    color: #bfc9cf;
    line-height: 24px;
    font-size: 16px;
  }
}

.logo {
  max-width: 240px;
  width: 100%;
  height: auto;
}
.version {
  display: flex;
  align-items: center;
  margin-top: 20px;
  left: 50%;
  svg {
    margin-right: 6px;
  }
}
