.space-top {
  margin-top: 3rem;
}
.space-bottom {
  margin-bottom: 2rem !important;
}
.failed-row {
  td{
    border-bottom: 1px solid #da4242 !important;
    border-top: 1px solid #da4242 !important;
  }
  td:last-child {
    border-right: 1px solid #da4242 !important;
  }
  td:first-child {
    border-left: 1px solid #da4242 !important;
  }
}
.failed-row + .failed-row{
  td{
    border-top: none !important;
  }
}

.inline-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  & > .full-width {
    flex-basis: 100%;
  }
  & > * {
    flex-basis: 48%;
  }
}
.form-item {
  width: 47% !important;
  margin-right: 0 !important;
  @media (max-width: 768px) {
    width: 100% !important;
  }
  .ant-form-item-control {
    width: 100%;
  }
}
.mt2 {
  margin-top: 2rem;
}

.clickable-table-row {
  cursor: pointer;
}
.full-width {
  width: 100%;
}

.ant-table {
  .ant-table-row {
    min-height: 72px !important;
    height: 64px;
  }
}
.ant-table-thead > tr > th {
  background-color: #ffffff;
  color: #91a2ab;

  &::before {
    content: unset !important;
  }
}

.open-row-info-icon {
  color: #91a2ab;
  fill: currentColor;
  stroke: currentColor;
  transition: all 0.2s;
}

.ant-table-row:hover .open-row-info-icon {
  color: #1a3d56;
}

.invoice-period-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: fit-content;

  span {
    color: rgba(26, 61, 86, 0.34);
  }
}
.invoice-modal-header {
  .heading {
    margin: 0;
  }
  .print-link-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: underline;
    color: #2c56b6;
    font-weight: 500;
  }
  .icon {
    color: inherit;
    fill: currentColor;
    margin: 0 8px;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ant-divider.ant-divider-horizontal {
  position: relative;
}
.divider-link { 
    position: absolute; 
    color: #2c56b6;
    text-decoration: underline;
    font-weight: 500;
    background-color: white;
    z-index: 50;
    height: auto;
    padding: 0px 10px; 
    display: flex;
    top:0px;
    right:0px;
    &:hover{
      background-color: white;
    }
}
.failed-row .ant-btn{
  height: auto;
  padding: 8px 12px !important;
}
.reciept{
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: underline;
    color: #91a2ab;
    font-weight: 500;
    &:hover {
      color: #91a2ab;
    }
}