.main-container {
  transition: 0.2s;
  .page-content {
    height: 100%;
    margin: 20px;
  }
  @media screen and (min-width: 768px) {
    .page-content {
      margin: 36px;
      margin-top: 0;
    }
  }
}
