.image-uploader {
  div.ant-upload {
    border: none;
  }
  span.ant-upload {
    margin: 0 !important;
    border-radius: 36px !important;
    border: solid 2px #d0d8dd !important;

    color: #b6c1c8;

    &:hover,
    &:focus {
      color: #2c56b6;
      border-color: rgba(44, 86, 182, 0.5) !important;
    }
    &:active {
      border-color: #2c56b6 !important;
    }
  }

  &.big-uploader {
    width: 100% !important;

    height: 220px;
    .ant-upload-list,
    .ant-upload,
    .ant-upload-list-picture-card-container {
      height: 100%;
      width: 100%;
    }
    .ant-upload-list-item-image {
      object-fit: cover;
    }

    .ant-upload {
      border-radius: 8px !important;
      width: 100% !important;
    }
  }

  .upload-photo-inner-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: inherit;
  }
  .ant-upload-list-item-file {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.preview-pic-modal {
  max-width: 1024px;
  width: 100% !important;
  padding: 8px;
}
