.upload-doc-row {
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .view-doc-link-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    border-radius: 8px;
    padding: 0;
    width: 80px;
    height: 80px;
    background-color: #f5f7f8;
    border: 2px solid;
    color: #91a2ab;
    border-color: #d0d8dd;
    transition: all 0.25s;

    &.disabled {
      cursor: auto;
    }
    &:not(.disabled) {
      &:hover,
      &:focus {
        color: #2c56b6;
        border-color: rgba(44, 86, 182, 0.5);
      }
    }
  }

  .heading {
    color: #91a2ab;
    font-weight: 500;
    line-height: 1.3;
  }
  .doc-received-status-box {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    line-height: 1.3;
    .doc-title,
    .received-heading {
      font-weight: inherit;
      line-height: inherit;
    }
    .doc-title {
      font-size: 16px;
      margin-bottom: 4px;
    }
    .received-heading {
      font-size: 12px;
      margin-right: 4px;
    }
  }
  .print-doc-btn {
    color: #91a2ab;
    outline: none;
    background-color: transparent;
    border: none;
    transition: all 0.2s;
    cursor: pointer;

    span {
      text-decoration: underline;
      font-size: 14px;
      font-weight: 500;
    }

    &:hover,
    &:focus {
      color: #2c56b6;
    }
  }

  .doc-acceptance-status-box {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .accept-btn,
    .reject-btn {
      cursor: pointer;
    }
    & > *:not(:last-child) {
      margin-right: 16px;
    }
  }
}

.upload-doc-btn {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.upload-doc-btn .ant-upload span {
  cursor: pointer;
  background-color: #2c56b6 !important;
  transition: all 0.2s;
  border: 1.5px solid;
  border-color: #d0d8dd;
  color: #fff !important;
  border-radius: 8px;
  font-weight: 500;
  line-height: 1.3;
  font-size: 14px;
  display: inline-flex;
  padding: 11px 15px !important;
  height: fit-content;
  &:hover,
  &:focus {
    border-color: rgba(44, 86, 182, 0.7);
  }
}

.upload-doc-btn .ant-upload-disabled span {
  background-color: #f5f5f5 !important;
  color: #91a2ab !important;
}
