body {
  background-color: #e5e5e5;
  font-family: 'Poppins', sans-serif;
}
html,
body,
#root {
  min-height: 100%;
  height: 100%;
}

body {
  // To prevent the page width from jumping suddenly
  // when the scrollbar appears due to a change in page content
  // the !important is for that antd modal add inline style to body when is open that affect width of body
  width: 100vw !important;
  overflow-x: hidden !important;
}

body {
  line-height: 1.3;
  // overflow-y: scroll;
}

.force-full-width.force-full-width {
  width: 100% !important;
}
.ant-form-item-label {
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  color: #91a2ab !important;
  label {
    height: unset !important;
    line-height: 1.3;
    margin-bottom: 6px;
    margin-left: 16px;
    color: inherit;
    font-weight: 500;
    font-size: 16px;
  }
}
.expense-report .ant-modal-header,
.expense-report .ant-modal-footer {
  border: none;
}
.ant-input-number-handler-wrap {
  display: none;
}
.soft-transition {
  transition: all 0.2s;
}
.flex-column {
  flex-direction: column;
}

.auth-layout-heading {
  margin-bottom: 24px;
}
.cta-btn {
  font-weight: 500;
}
.password-visibility-btn {
  color: #324049;
  padding: 10px 8px;
  border: none;
  background-color: inherit;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  border-radius: 9999px;

  &:hover,
  &:focus {
    color: #2c56b6;
  }

  &:focus {
    background-color: lighten(#2c56b6, 50%);
  }
}

.icon-24 {
  width: 24px;
  height: 24px;
}

.icon-fill-inherit {
  fill: currentColor;
  color: inherit;
}

.secondary-btn {
  color: #2c56b6 !important;
  border-color: currentColor !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;

  span {
    line-height: 1.3;
    font-size: 14px !important;
  }

  &:hover,
  &:focus {
    background-color: lighten(#2c56b6, 50%) !important;
  }
  &:active {
    background-color: lighten(#2c56b6, 45%) !important;
  }
}

.tables-tab-bar.ant-tabs {
  .ant-tabs-nav {
    background-color: white;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid rgba(145, 162, 171, 0.3);
    &::before {
      border: none;
    }
    .ant-tabs-nav-wrap {
      height: fit-content;
    }
    .ant-tabs-nav-list {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .ant-tabs-ink-bar {
      display: none;
    }
    .ant-tabs-tab {
      margin: 0 !important;
      margin-right: 8px !important;
      padding: 12px 16px;
      line-height: 1.3;
      border-radius: 8px !important;
      border: none;
      background-color: transparent;
      font-size: 14px;
      font-weight: 500;
      color: #91a2ab;

      &.ant-tabs-tab-active {
        color: #2c56b6;
        background-color: rgba(44, 86, 182, 0.3);
      }
    }
  }
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr:not(:last-child) > td {
  border-bottom: 1px solid #e5e5e5;
}
.ant-table-tbody > tr > td {
  font-weight: 500;
  color: #1a3d56;
}
.bordered-table {
  border: solid 1px #e5e5e5 !important;
  border-radius: 8px;
  overflow: hidden;
}
.selected-table-row {
  background-color: #cee5fb;
  pointer-events: none;
}

.custom-accordion {
  border: none;
  background-color: transparent;
  .ant-collapse-item {
    border-radius: 8px !important;
    margin-bottom: 24px;
    border: none;
    background-color: #ffffff;
    overflow: hidden;

    .ant-collapse-header {
      font-weight: bold;
      color: #1a3d56;
    }
    .ant-collapse-content {
      border: none;
      border-top: 1px solid #e5e5e5;
      border-radius: 8px;
    }
    .ant-collapse-content-box {
      padding: 0;
    }
    .expand-more-icon {
      transform: translateY(-50%) rotate(0);
      transition: all 0.2s;

      &.rotate-180 {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
}

.ant-divider {
  color: inherit !important;

  &.ant-divider-horizontal {
    margin: 24px 0;
  }
  .ant-divider-inner-text {
    font-size: 16px;
    line-height: 1.3;
    font-weight: 500;
  }
  &.ant-divider-with-text-left {
    .ant-divider-inner-text {
      padding: 0;
      padding-right: 16px;
    }
    &::before {
      content: unset;
    }
    &::after {
      border-top: 1px solid #e5e5e5;
    }
  }
}

.date-input-suffix-icon {
  margin-right: 12px;
  cursor: pointer !important;
  pointer-events: all;
}
.date-input-clear-icon {
  margin-right: 12px;
  font-size: 24px;
}

.ant-modal-confirm-btns {
  .ant-btn {
    font-size: 14px;
    font-weight: 500;
    padding: 11px 15px;
    line-height: 1.3;
    height: fit-content;
  }
}
.reset-error-form {
  .ant-form-item-explain,
  .ant-form-item-extra {
    min-height: 0 !important;
  }
}

.none-border {
  tr > td,
  tr > th {
    border-right: none !important;
  }
  tr > td {
    cursor: pointer;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ant-message-error {
  text-transform: capitalize;
}

.btn-off-on {
  display: flex;
  justify-content: flex-end;
}

.status-align-service {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrapper-last-mot {
  display: flex;
  align-items: center;
  input {
    width: auto;
  }
  label {
    margin-left: 11px;
    width: 800px;
    font-weight: 500;
  }
}
.open-expenses {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0 0;
  flex-basis: 100% !important;
}
.without-margin {
  margin: 0 !important;
}
.sub-state-mot {
  margin-top: 5px;
  color: #91a2ab;
  font-size: 12px;
}
.tetst {
  position: relative;
  .ant-divider-inner-text {
    a {
      position: absolute;
      right: 0;
      z-index: 10;
      background-color: #ffffff;
      padding-left: 20px;
      font-size: 14px;
    }
  }
}
.no-cancel {
  padding-top: 40px;
  button[aria-label='Close'] {
    display: none !important;
  }
  .ant-modal-header {
    display: none !important;
  }
  .ant-modal-body {
    padding-top: 40px !important;
    padding-bottom: 0px !important;
  }
}
.btn-denger {
  border: solid 1.5px #da4242;
  color: #da4242;
  &:hover,
  &:focus {
    color: #da4242;
    border-color: #da4242;
  }
}

.ant-badge-count {
  box-shadow: none !important;
}

.book-appointment .ant-divider.ant-divider-horizontal {
  margin: 15px 0;
}
.map-appointment {
  margin-top: 8px;
  height: 240px;
  flex-basis: 100% !important;
  border-radius: 8px;
  border: solid 1px #d0d8dd;
}

.service-hours {
  color: #91a2ab;
  font-size: 12px;
}
th.today {
  background-color: #2c56b6 !important;
  color: #fff !important;
}
.today {
  border-left: 2px solid #2c56b6 !important;
  border-right: 2px solid #2c56b6 !important;
}
.weekly-picker {
  margin: auto;
  margin-bottom: 16px;
  padding: 14px 40px 14px !important;
  input {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }
}
.btn-date-range {
  border: 2px solid #000;
  border-radius: 6px !important;
  width: 24px;
  height: 24px;
  padding: 0 !important;
  .ant-pagination-disabled {
    line-height: normal !important;
    border: 2px solid #000 !important;
  }
  &:hover,
  &:active,
  &:focus {
    border: 2px solid #000 !important;
  }
}

.prev-week {
  transform: translate(150%, 55%);
  position: relative;
  z-index: 10;
}
.next-week {
  transform: translate(-150%, 55%);
  -webkit-transform: translate(-150%, 55%);
  position: relative;
  z-index: 10;
}

.ant-upload-disabled {
  &:hover {
    border-color: inherit !important;
  }
  background-color: #f5f5f5 !important;
  color: rgb(182, 193, 200) !important;
  border-radius: 8px;
  cursor: not-allowed !important;
}

.rejection-reason {
  padding: 0 16px 16px 16px;
  border-radius: 8px;
  flex-basis: 100% !important;
  background-color: #da4242;
  color: #fff;
  margin-top: 30px;
}

.wrapper-footer-mot {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  button {
    margin: 0 !important;
    padding: 0 40px !important;
    height: 56px;
  }
}
.map {
  border-radius: 8px;
}
.is_holidays {
  &:not(th) {
    background-color: #f5f6f8 !important;
    cursor: not-allowed;
  }
}
// .ant-select-disabled.ant-select:not(.ant-select-customize-input)
//   .ant-select-selector,
// .ant-picker-input > input[disabled],
// .ant-input[disabled] {
//   color: #000 !important;
// }
.box {
  background-color: #fff;
  border-radius: 8px;
  border: solid 1px #e5e5e5;
  margin-bottom: 24px;
  padding: 0 24px;
  .color-text {
    color: #91a2ab !important;
  }
  .wrapper-settings {
    display: flex;
    justify-content: left;

    flex-wrap: wrap;
    .itme-settings {
      flex-basis: 30%;
      margin-right: 40px;
    }
  }
}

.admin-table {
  th {
    background-color: #e5e5e5 !important;
    color: #91a2ab;
  }
  .ant-table-content {
    overflow: auto !important;
  }
}
.title-upload-pic-pco {
  color: #91a2ab;
  font-weight: bold;
  margin-bottom: 8px;
}

.accident-modal-car-status {
  display: flex;
  flex-basis: 100% !important;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #d0d8dd;
  border-radius: 8px;
  .insurance-cost span {
    font-size: 12px;
    font-weight: 500;
    color: #91a2ab;
    margin-right: 30px;
  }
}
.accident_images {
  flex-basis: 100% !important;
  .ant-upload-list-picture-card-container {
    width: 336px;
    height: 192px;
  }
  .ant-upload {
    width: 336px;
    height: 192px;
  }
}
.gocardless-btn {
  border-color: #da4242 !important;
  background-color: #fff !important;
  color: #da4242 !important;
}

.ant-table-filter-column {
  justify-content: flex-start !important;
}

.ant-table-column-title {
  flex: none !important;
}

.table-complex {
  .ant-table-thead {
    .ant-table-cell {
      background: #f5f6f8;
      color: #244658;
    }
  }
}

// .ant-table-column-sorters {
//   justify-content: unset !important;
//   .ant-table-column-title {
//     flex: unset !important;
//     margin-right: 12px;
//   }
// }

.box_table {
  background-color: #fff;
  box-shadow: 0px 8px 24px -16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 24px !important;
  th {
    background-color: #d0d8dd !important;
    color: #244658 !important;
  }
}

.dashboard {
  td {
    padding: 0 auto !important;
  }
  .ant-table .ant-table-row {
    height: auto !important;
  }
}
.danger {
  color: #da4242;
}
.warning {
  color: #da9842;
}

.mt-2 {
  margin-top: 2em;
}
.table_deposit {
  border: 1px solid #bbbbbb;
  padding: 16px;
  border-radius: 12px;
  .ant-divider {
    margin-top: 8px;
  }
}

.warning-box {
  background: rgba(218, 66, 66, 0.08);
  border-radius: 8px;
  h1 {
    color: #da4242;
    font-size: 20px;
    font-weight: bold;
    svg {
      margin-right: 12px;
    }
  }
  font-size: 16px;
  padding: 25px 29px;
  margin-bottom: 24px;
}
.ant-input-number-disabled,
.ant-input[disabled] {
  color: #5a5a5a;
}
.tabs-deposit-invoice {
  .ant-tabs-nav {
    background-color: #f5f6f8 !important;
  }
  .ant-tabs-ink-bar {
    background-color: unset !important;
  }
  .ant-tabs-nav-wrap {
    background-color: #f5f6f8 !important;

    padding: 12px 12px;
    .ant-tabs-tab {
      padding: 12px;
      border-radius: 8px;
      border-bottom: none !important;
      overflow: hidden !important;
    }
    .ant-tabs-tab-active {
      background-color: #fff;
      box-shadow: 0px 8px 24px -16px rgba(0, 0, 0, 0.04) !important;
    }
  }
}

.invoice-row-active {
  background-color: #dddddd !important;
  cursor: pointer;
  &:hover {
    background-color: #cacaca !important;
  }
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: unset;
}
