.error-boundary-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 100%;
  min-height: 100%;
  padding: 12px 24px;
  .ant-alert {
    max-width: 100%;
    margin-bottom: 12px;
  }
  .actions > *:not(:last-child) {
    margin-right: 8px;
  }
  .alert-message {
    margin-top: 24px;
  }
}
