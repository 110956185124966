.header-panel {
  background: none;
  padding: 36px 0 16px;
  margin: 0;
  font-weight: 500;
  line-height: inherit;
  height: fit-content;

  background-color: #f0f2f5;

  .page-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 4px;
  }
  .sub-title {
    color: #91a2ab;
    font-size: 16px;
    margin: 0;
    font-weight: 500;
  }
}
