.go-back-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  padding: 12px 4px;
  span {
    margin: 0 4px 0 18px;
  }
  transform: scale(1);
  opacity: 1;
  transition: all 0.2;

  &.hidden {
    transition: all 0.2;
    pointer-events: none;
    opacity: 0;
    transform: scale(0.6);
  }
}
