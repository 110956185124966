.damage-sketch-template-modal {
  top: 0 !important;
  bottom: 0 !important;
  width: 100% !important;
  max-width: 1240px !important;
}

.damage-sketch-template {
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: url('../../../assets/PdfTemplateHeader@3x.png');
    background-position: top right;
    background-size: cover, contain;
    background-repeat: no-repeat;
    padding-top: 32px;
    margin-bottom: 32px;
  }
  .template-title {
    display: inline-block;
    font-size: 24px;
    padding: 8px 16px;
    color: white;
    text-transform: uppercase;
    background-color: #f16d24;
    font-weight: 600;
    text-align: center;
    width: 100%;
    max-width: 424px;
    align-self: flex-end;
    margin: 0;
  }
  .logo {
    width: 100%;
    max-width: 258px;
    height: auto;
    padding: 0 32px;
  }
  .heading {
    font-size: 18px;
    font-weight: bold;
    color: #244658;
    line-height: 1.5;
  }
  .section {
    margin-bottom: 32px;
  }
  .description {
    padding: 18px;
    border-radius: 8px;
    border: 1px solid #d0d8dd;
    margin: 24px 0;
    font-size: 14px;
    font-weight: 500;
    color: #1a3d56;
    p {
      margin-bottom: 8px;
      line-height: 1.5;
    }
  }
  .ant-form-item-label {
    width: 110px;
    justify-content: flex-end;
    label {
      font-size: 14px;
      font-weight: 500;
      color: #244658;
    }
  }
  .ant-form-item {
    margin-bottom: 8px;
  }
  .ant-form-item-control-input-content {
    .ant-input,
    .ant-input-number-input,
    .ant-picker {
      background-color: #d0d8dd;
      color: #244658;
      font-weight: bold;
      font-size: 14px;
      border-radius: 8px;
      border: none;
      min-height: 36px;
    }
  }
  .capture-signature-section,
  .fuel-and-address-section {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 14px;
    color: #244658;

    & > *:not(:last-child) {
      margin-right: 24px;
    }
    .flex-col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
    .title {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 12px;
      line-height: 1.5;
      color: #1a3d56;
    }
    .value {
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
  .capture-signature-section {
    .signature-pad-placeholder {
      cursor: pointer;
      border-radius: 8px;
      border: 2px solid #d0d8dd;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #91a2ab;
      height: 173px;
      width: 258px;
      transition: all 0.3s;
      &:hover {
        color: #2c56b6;
        border-color: currentColor;
      }
    }
  }
  .fuel-and-address-section {
    .fuel-capacity-column {
      align-self: center;
      margin-right: 64px;
      .title {
        display: flex;
        align-items: center;
      }
      .fuel-pump-icon {
        margin-right: 12px;
      }
      .full-capacity-input {
        max-width: 136px;
      }
    }
    //
  }

  .car-damages-table {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 56px;
    .table-header {
      flex: 1;
      flex-basis: 100%;
      border: 1px solid #91a2ab;
      background-color: #fce1d3;
      color: #1a3d56;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 6px 14px;
      font-size: 14px;
      h2 {
        font-size: inherit;
        margin: 0;
        font-weight: bold;
        color: #1a3d56;
      }
      .add-new-damage-btn {
        font-weight: bold;
        font-size: 14px;
        border-radius: 0;
        background-color: #f16d24;
        color: white;
        border: none;
      }
    }

    .damages-row {
      width: 100%;
      align-items: stretch !important;
      // justify-content: stretch;
    }
    .damage-record {
      display: flex;

      // flex-basis: 50%;
      height: auto;
      max-height: 340px;
      border: 1px solid #91a2ab;
      border-top: none;
      border-left: none;
      .damage-number-cell {
        border: 1px solid #91a2ab;
        border-top: 0;
        border-bottom: 0;
        span {
          margin: 16px 32px;
          display: inline-block;
          width: 2ch;
          text-align: center;
        }
        border-top: none;
      }
      .damage-image-wrapper {
        width: 100%;
        padding: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
      }
      .damage-image {
        object-fit: contain;
        max-width: 100%;
        width: auto;
        height: 100%;
        max-height: 340px;
      }
    }
  }
}
.add-damage-modal-heading {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  color: #1a3d56;
}
.car-sketch-img {
  width: 100%;
  height: auto;
  max-width: 348px;
}
