.history {
  margin-top: 24px;
}
.new-tikets {
  margin-bottom: 24px;
  .ant-collapse-header {
    padding: 18px 24px !important;
  }
  .ant-collapse-content-box {
    padding: 0;
  }
  .ant-table-thead > tr > th {
    padding: 18px 24px !important;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr:not(:last-child) > td {
    border-bottom: solid 1px #e5e5e5;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 26px 24px;
  }
}
.title-panel {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
  color: #1a3d56;
}
