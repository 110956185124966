.heading-container {
  padding: 18px 16px;
  background-color: #f5f6f8;
  border-radius: 8px;
  line-height: 1.5;

  .title {
    color: #244658;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 8px;
  }
  .sub-title {
    color: rgba(26, 61, 86, 0.5);
    font-size: 14px;
    font-weight: 500;
    margin: 0;
  }
}
