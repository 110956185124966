.ant-modal.styled-modal {
  padding: 24px 12px;
  top: 18px;
  .ant-modal-body {
    padding: 0 40px 18px;
  }
  .ant-modal-header {
    border: none;
    padding: 18px 40px;
    min-height: 86px;
  }

  .footer-cta-btns-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    & > *:not(:last-child) {
      margin-right: 16px;
    }
  }
  .footer-cta-btn {
    padding: 6px 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;

    width: 100%;
    max-width: 336px;
    &.half-width {
      max-width: 168px;
    }
    &.ghost {
      color: #91a2ab;
      border-color: currentColor;
      &:hover,
      &:focus {
        color: #1a3d56;
      }
    }
  }
  .ant-modal-footer {
    border: none;
    padding: 24px 40px 40px;
  }
  .ant-modal-close {
    margin: 18px 24px;
    padding: 0 12px;
  }
  .ant-modal-close-x {
    display: flex !important;
    align-items: center;
    width: max-content;
    padding: 12px 4px;
    color: #1a3d56;
    transition: all 0.2s;
    border-radius: 8px;
    &:hover,
    &:focus {
      background-color: rgba(0, 0, 0, 0.02);
    }
    height: unset !important;
    line-height: 1.3;
    &::after {
      font-weight: 500;
      color: inherit;
      content: 'Close';
      margin-left: 18px;
    }
  }
  .ant-modal-title {
    font-size: 20px;
  }
  .ant-modal-header {
    display: flex;
    align-items: center;
  }
}
