.styled-steps {
  margin: 36px 0 42px;
}
.ant-steps-item {
  &-title {
    font-weight: 500;
  }
  &-process &-title {
    color: #2c56b6 !important;
  }
  &-finish &-title {
    color: #91a2ab !important;
  }
  &-wait &-title {
    color: #d0d8dd !important;
  }
  &-error &-title {
    color: #ff4d4f !important;
  }
}
.ant-steps-icon,
.ant-steps-item-icon {
  width: 18px !important;
  height: 18px !important;
}
.ant-steps-item-icon {
  border: none;
  background-color: transparent;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
