.capture-signature-modal {
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
}
.signature-canvas {
  border: 2px solid #d0d8dd;
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
  height: 400px;
}

.error-line {
  color: #ff4d4f;
  font-size: 14px;
  display: block;
  align-self: flex-start;
  margin: 8px 0;
}
