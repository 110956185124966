.select-filter {
  width: 250px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  &__row {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
}
